<script setup lang="ts">
import WebViewer from '@pdftron/webviewer'
import type { WebViewerInstance, WebViewerOptions } from '@pdftron/webviewer'
import {
  disabledElementsPDFTron,
  disabledToolsPDFTron,
} from '~/constants/PDFTronConstants'

interface Props {
  path?: string
  initialDoc?: string | null
  options?: Omit<WebViewerOptions, 'path' | 'initialDoc' | 'licenseKey'>
}

const props = withDefaults(defineProps<Props>(), {
  path: 'webviewer',
  initialDoc: null,
  options: () => ({}),
})

const emit = defineEmits(['init'])

const { baseURL, pdftronKey } = useRuntimeConfig().public
const viewer = shallowRef<HTMLElement>()
const instance = shallowRef<WebViewerInstance>()

const init = async () => {
  if (!viewer.value) {
    return
  }

  // TODO: add withCredentials to PDFTron options
  const viewerInstance = (instance.value = await WebViewer(
    {
      ...props.options,
      path: `${baseURL}/${props.path}`,
      licenseKey: pdftronKey,
      fullAPI: true,
      disableLogs: true,
      css: '/assets/css/webviewer.1.css',
    },
    viewer.value,
  ))

  const { disableLogs } = viewerInstance.Core
  const { disableElements, disableTools, loadDocument } = viewerInstance.UI

  loadDocument(props.initialDoc, {
    withCredentials: true,
  })

  // Disable logs
  disableLogs(true)
  // Disable UI elements
  disableElements(disabledElementsPDFTron)
  disableTools(disabledToolsPDFTron)

  emit('init', viewerInstance)
}

onMounted(() => {
  init()
})

defineExpose({
  getInstance: () => instance,
})
</script>

<template>
  <div ref="viewer" class="pdf-viewer flex max-h-full"></div>
</template>

<style lang="postcss" scoped>
:deep(.pdf-viewer iframe) {
  @apply !h-full w-full;
}
</style>
