const disabledElementsPDFTron = [
  'ribbons',
  'custom-ribbons-container',
  'toggleNotesButton',
  'selectToolButton',
  'thumbnailControl',
  'pageManipulationOverlay',
  'thumbnailsControlRotatePopupTrigger',
  'thumbnailsControlManipulatePopupSmallTrigger',
  'signatureValidationModal',
  'rotateClockwiseButton',
  'rotateHeader',
  'rotateCounterClockwiseButton',
  'toggleCompareModeButton',
  'coverLayoutButton',
  'toolsHeader',
  'annotationPopup',
  'textPopup',
  'copyTextButton',
  'textHighlightToolButton',
  'textUnderlineToolButton',
  'textSquigglyToolButton',
  'textStrikeoutToolButton',
  'linkButton',
  'panToolButton',
  'panTool',
  'FreeHandAnnotation',
  'freeHandHighlightToolButton',
  'markInsertTextToolButton',
  'markReplaceTextToolButton',
  'settingsKeyboardButton',
  'menuButton',
  'marqueeToolButton',
]

const disabledToolsPDFTron: string[] = [
  'AnnotationCreateArrow',
  'AnnotationCreateCallout',
  'AnnotationCreateEllipse',
  'AnnotationCreateFreeHand',
  'AnnotationCreateFreeText',
  'AnnotationCreateLine',
  'AnnotationCreatePolygon',
  'AnnotationCreatePolygonCloud',
  'AnnotationCreatePolyline',
  'AnnotationCreateRectangle',
  'AnnotationCreateSignature',
  'AnnotationCreateStamp',
  'AnnotationCreateSticky',
  'AnnotationCreateTextHighlight',
  'AnnotationCreateTextSquiggly',
  'AnnotationCreateTextStrikeout',
  'AnnotationCreateTextUnderline',
  'AnnotationEdit',
  'FreeHandAnnotation',
]

export { disabledElementsPDFTron, disabledToolsPDFTron }
